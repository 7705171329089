import {IS_BROWSER} from "../constants/Browser";

export const returnInitialDataOrObject = (serverData = undefined) => {
    return IS_BROWSER && (window.__INITIAL_STATE__ && Object.keys(window.__INITIAL_STATE__).length) ? window.__INITIAL_STATE__ : serverData;
}

export const resetInitialData = () => {
    if (IS_BROWSER) {
        window.__INITIAL_STATE__ = null;
    }
}