import React from "react";
import './projectlist.component.scss';
import ProjectListItem from "./ProjectListItem";

const ProjectListView = ({results = []}) => {
    return (
        <div className="project-list__view py-2 px-2">
            {results.map(item => (
                <ProjectListItem key={item.id} project={item} />
            ))}
        </div>
    )
}

export default ProjectListView;