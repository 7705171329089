import React, {useState} from "react";
import './fetcher.component.scss';
import {Fetcher as BaseFetcher} from '@asgrachyov/fetcher';
import {FetcherLoader} from "../Loaders";
import {resetInitialData} from "../../utils/server";

const FetcherWrapper = (children, loadedByDefault = false) => {
    const [loading, setLoading] = useState(!loadedByDefault);
    return props => {
        if (!props.isLoading) {
            setLoading(false);
            resetInitialData();
        }
        return <div className={`fetcher-wrapper ${!loading ? 'fetcher-wrapper--loaded' : ''}`}>
            {children(props)}
        </div>
    }
}

const Fetcher = ({children, ...props}) => {
    return (
        <BaseFetcher
            {...props}
            loading={FetcherLoader}
            showResponseAnyway={true}
            showNextButton={false}
            showError={false}
            enableReinitialize={false}
            paginate={true}
            children={FetcherWrapper(children, !!(props.presetResults || props.presetResponse))}
        />
    )
}

export default Fetcher