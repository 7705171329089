import React from "react";
import {useTranslation} from "react-i18next";

const ProjectListEmpty = () => {
    const {t} = useTranslation();
    return <div className="project-list__empty">
        {t('ProjectList.Empty')}
    </div>
}

export default ProjectListEmpty