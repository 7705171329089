const srcSetSizes = [
    1920,
    1280,
    960,
    720,
    480,
    320
]

export const createSrcSetFromS3Object = s3Object => {
    const srcSet = [];

    srcSetSizes.forEach(size => {
        // if (size > +s3Object.s3_source_width) {};
        srcSet.push(`${s3Object[`s3_image_${size}`]} ${size}w`);
    });
    return srcSet.join(',');
}