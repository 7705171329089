import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {preparePath} from "../../utils/router";
import {PATHS} from "../../constants/Paths";
import CoolImage from "../CoolImage";
import {SITE_NAME} from "../../constants/Website";
import {LANGUAGES} from "../../utils/i18n";
import {createSrcSetFromS3Object} from "../../utils/image";

const ProjectListItem = ({project}) => {

    const [loaded, setLoaded] = useState(true);

    return (
        <div itemScope itemType="https://schema.org/CreativeWork" className={`project-list__view__item project-list__view__item--${project.size} py-2 px-2`}>

            {/*<meta itemProp="url" content={preparePath(PATHS.PROJECT_DETAIL.path, {project_slug: project.slug}, true)} />*/}
            <meta itemProp="identifier" content={project.id}/>
            <meta itemProp="isFamilyFriendly" content={'true'} />
            <meta itemProp="creator" content={SITE_NAME} />
            <meta itemProp="inLanguage" content={LANGUAGES[0]}/>
            <meta itemProp="dateCreated" content={new Date(project.created).toISOString()} />

            <Link itemProp="url" className="project-list__view__item__link" aria-label={project.title} to={preparePath(PATHS.PROJECT_DETAIL.path, {project_slug: project.slug})}>
                <div className={`project-list__view__item__inner${project.text_relative ? ' project-list__view__item__inner--text-relative' : ''}`} style={{backgroundColor: project.gradient ? `${project.color}` : null}}>
                    <div className="project-list__view__item__image">
                        {project.s3_image_object &&
                            <CoolImage itemProp="image" width={project.s3_image_object.s3_source_width} height={project.s3_image_object.s3_source_height} src={project.s3_image_object.s3_source} srcSet={createSrcSetFromS3Object(project.s3_image_object)} alt={project.title} loader={false} loaderStyle={{backgroundColor: 'rgba(0, 0, 0, .92)'}}/>
                        }
                    </div>                                                                                                                                                               {/* gradient looks extremely bad when no image */}
                    <div className={`project-list__view__item__text project-list__view__item__text--${project.text_position} project-list__view__item__text--color-${project.text_color}${project.gradient && loaded ? ' project-list__view__item__text--gradient' : ''}${project.is_max_width ? ' project-list__view__item__text--max-width' : ''}`}>
                        <div className="project-list__view__item__text__inner">
                            <div className="project-list__view__item__text--title">
                                {/*<Link aria-label={project.title} to={}>*/}
                                <span itemProp="name">{project.title}</span>
                                {/*</Link>*/}
                            </div>
                            <div className="project-list__view__item__text--excerpt d-none">
                                <span itemProp="description">{project.excerpt || project.title}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default ProjectListItem