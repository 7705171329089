import React from "react";
import './projectlist.component.scss';
import Fetcher from "../Fetcher";
import api from "../../api";
import ProjectListView from "./ProjectListView";
import ProjectListEmpty from "./ProjectListEmpty";
import {returnInitialDataOrObject} from "../../utils/server";

const ProjectList = ({serverData = null}) => {
    const presetResults = returnInitialDataOrObject(serverData);
    return (
        <div className="project-list">
            <Fetcher
                api={api.getProjects}
                presetPage={presetResults ? 1 : null}
                presetResults={presetResults?.results}
                infinite={true}
                infiniteOffset={2000}
                initFilter={{
                    page: 1,
                    page_size: 12,
                    is_promoted: true
                }}
            >
                {({
                      count,
                      results,
                      isLoading
                  }) => {
                    if (results.length > 0) {
                        return <ProjectListView results={results} count={count}/>
                    } else {
                        return !isLoading ? <ProjectListEmpty /> : null;
                    }
                }}
            </Fetcher>
        </div>
    )
}

export default ProjectList