import React, {useEffect, useRef, useState} from "react";
import './coolimage.component.scss';
import {PulseLoader} from "react-spinners";
import {IS_BROWSER} from "../../constants/Browser";

const CoolImage = ({
                       src,
                       srcSet,
                       ref,
                       onLoad = () => {},
                       style,
                       alt,
                       className = '',
                       loader = true,
                       loaderStyle = {},
                       itemProp = null,
                       width = 0,
                       height = 0
                   }) => {

    const [loaded, setLoaded] = useState(false);
    const imageRef = useRef(null);

    const handleLoaded = () => {
        setLoaded(true);
        onLoad();
    }

    useEffect(() => {
        if (imageRef.current && imageRef.current.complete && imageRef.current.naturalHeight !== 0) {
            handleLoaded();
        }
    }, []);

    return <>
        {!loaded &&
            (
                loader && IS_BROWSER // temp solution, this loader does not work on server-side for some reason, probably non of them work, actually
                    ? <>
                        <div style={loaderStyle} className="position-absolute coolimage__loader">
                            <div className="coolimage__loader__inner w-100 h-100">
                                <PulseLoader color={'#ff0000'} margin={4}/>
                            </div>
                        </div>
                    </>
                    : null
            )
        }
        <img ref={imageRef}
             width={width}
             height={height}
             onLoad={() => handleLoaded()}
             className={`coolimage${className ? ` ${className}` : ''}${!loaded ? ' opacity-0' : ''}`}
             src={src}
             alt={alt}
             srcSet={srcSet}
             style={style}
             itemProp={itemProp}
        />
    </>
}

export default CoolImage